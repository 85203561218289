.login-page {
  background: url("../../assets/images/login/background@3x.jpg") no-repeat center -190px;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;

  &__bg {
    background: #F4F4F4;
    width: 200%;
    height: calc(100% - 330px);
    display: block;
    border-radius: 50% 50% 0 0;
    position: absolute;
    top: 330px;
    left: -50%;
    right: -50%;
  }

  &__bg-overlay {
    background: #888888 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -50%;
    right: -50%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 99;
    padding-top: 48px;
    padding-bottom: 48px;

    @include media-screen(xs) {
      padding-top: 20px;
    }
  }

  &__logo-ecodrop {
    width: 118px;
    height: 52px;

    @include media-screen(xs) {
      width: 90px;
      height: 40px;
    }
  }

  &__title {
    color: $color-white;
    text-align: center;
    padding-top: 28px;
    padding-bottom: 48px;

    @include media-screen(xs) {
      padding-top: 20px !important;
      max-width: 95%;
      font-size: 30px;
    }
  }

  .login-form {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    max-width: 513px;
    width: 90%;
    // height: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 40px;
    padding-bottom: 40px;
    .ant-form {
      width: 100%;
      label {
        font: $font-label;
        color: $color-dark;
      }
    }

  }

}


.login-form {

  &__title {
    padding-bottom: 36px;
    font: normal normal normal 21px/25px $font-family;
    color: $text-dark;
  }

  &__forgot {
    font: $font-subtitle-sx;
    color: $text-grey;
  }

  .ant-form label {
    font: $font-subtitle-sx;
    color: $color-dark;
  }

  .ant-input {
    border: none;
    border-color: transparent;
    border-radius: unset;
    background: #FFFFFF;
    outline: none !important;
    box-shadow: none;
    color: $text-dark;
    font: $font-input;
    padding: 10px 18px;

    &:hover {
      border-color: transparent;
      border-right-width: 0;
    }

    &::placeholder {
      color: $text-grey;
      font-style: italic;
      // opacity: 1;
    }

  }

  .ant-input-affix-wrapper {
    border: none;
    border-color: transparent;
    border-radius: unset;
    outline: none !important;
    box-shadow: none;
    padding: 0;
    border-bottom: 1px solid $border-primary;
    color: $text-grey;
    font: $font-input;
  }

  .ant-input-suffix {
    border: none;
  }

  .ant-checkbox-wrapper {
    font: $font-content-sx;
    color: $color-dark;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-inner {
    // border-color: transparent;
    // border: none !important;
    border: 1px solid $border-primary;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    transition: none;

    &::after {
      left: 16%;
      width: 8px;
      height: 14px;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: 2px solid $color-primary;
    }

    .ant-checkbox-inner {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    border-color: #ff7875;
    border-right-width: 1px;
    outline: 0;
    box-shadow: none;
  }

  // .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  //   border-color: $border-primary;
  // }
}

