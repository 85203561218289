.custom-form {
  .ant-form {
    width: 100%;
  }

  .ant-form label {
    font: $font-label;
    color: $color-dark;
  }
}

.ant-picker {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

}

.ant-picker:hover,
.ant-picker-focused {
  border-color: transparent;
}

.ant-picker-input>input {
  color: $color-primary;

  &::placeholder {
    opacity: 1;
    color: $color-primary;
  }
}


.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $color-primary;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $color-primary;
}

.ant-picker-today-btn {
  color: $color-primary;
}

@media (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label {
    flex: unset;
    margin-right: 12px;
  }

  .ant-form .ant-form-item .ant-form-item-control {
    flex: 1;
  }
}
